import React, { forwardRef, useMemo, useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Tile.module.scss';
import moduleStyles from '../NewsCarousel.module.scss';
import { Link, ResponsiveLazyImage } from 'sana/elements';
import { newsItemPage } from 'sana/routes';
import { renderHTML } from 'sana/utils';
import { UseSanaTexts } from 'sana/texts';
import { LazyContent } from '../../utils/lazyLoader';
import { setNewsHtml, formatDate, dateToString } from '../../utils/helpers';
import { TextKeys } from '../../utils/textKeysConstants';

const NewsTile = forwardRef(({ model, modelSettings, onKeyDownReadMoreLink, onKeyDownNewsTitle }, ref) => {
  const { publishedDate, featuredImage } = modelSettings.news;
  const refNewsTitle = useRef();
  const refReadMoreLink = useRef();

  useImperativeHandle(ref, () => ({
    get newsTitle() {
      return refNewsTitle.current;
    },
    get readMoreLink() {
      return refReadMoreLink.current;
    },
  }));

  const to = useMemo(() => newsItemPage(model.id), [model]);
  return (
    <div className={classNames(styles.newsContent, 'news-carousel-news-content', model.id === undefined && styles.contentLoading)}>
      <div className={styles.newsDetails}>
        <div className={styles.newsInner}>
          <LazyContent
            className={styles.imageLoader}
            wait={model.url}
          >
            <Link url={model.url} to={to} tabIndex={-1} aria-label={model.title}>
              {featuredImage && (
                <div
                  className={classNames(
                    styles.imgWrp,
                    model.image && !model.image.src ? styles.noImage : null,
                    'news-carousel-news-content',
                  )}
                >
                  <LazyContent
                    className={classNames(styles.imageLoader, styles.noImage)}
                    wait={model.image && model.image.src ? 1 : undefined}
                  >
                    {model.image && model.image.src ? (
                      <ResponsiveLazyImage
                        src={model.image.src}
                        alt={model.image.alt ? model.image.alt : model.title}
                        className={styles.image}
                        title={model.title}
                        draggable="false"
                      />
                    ) : null}
                  </LazyContent>
                </div>
              )}
            </Link>
          </LazyContent>

          <div className={styles.newsTitle}>
            <LazyContent
              className={styles.imageLoader}
              wait={model.title}
            >
              <Link
                id={`${model.id}-news-title`}
                ref={refNewsTitle}
                url={model.url}
                to={to}
                tabIndex="0"
                title={model.title}
                aria-label={model.title}
                onKeyDown={onKeyDownNewsTitle}
              >
                <span className="h3">{model.title}</span>
              </Link>
            </LazyContent>
          </div>
          {publishedDate && (
            <div className={styles.newsDate}>
              <LazyContent
                className={styles.imageLoader}
                wait={model.date ? 1 : undefined}
              >
                <>
                  <span aria-hidden>
                    {formatDate(model.date)}
                  </span>
                  <span className={moduleStyles.visuallyHidden}>
                    <UseSanaTexts textKeys={TextKeys}>
                      {text => (`${text[4]} ${dateToString(model.date)}`)}
                    </UseSanaTexts>
                  </span>
                </>
              </LazyContent>
            </div>
          )}
          <div className={styles.newsDetail} onKeyDown={onKeyDownReadMoreLink}>
            <LazyContent
              className={styles.imageLoader}
              wait={model.shortDescription}
            >
              <>
                {renderHTML(
                  setNewsHtml(model),
                )}
                <UseSanaTexts textKeys={TextKeys}>
                  {
                    text => (
                      <Link url={model.url} ref={refReadMoreLink} to={to} tabIndex={0} className={styles.readMore} aria-label={`${text[1]} ${model.title}`}>
                        <span>
                          {text[1]}
                        </span>
                      </Link>
                    )
                  }
                </UseSanaTexts>
              </>
            </LazyContent>
          </div>
        </div>
      </div>
    </div>
  );
});
NewsTile.propTypes = {
  model: PropTypes.object,
  modelSettings: PropTypes.object,
  onKeyDownReadMoreLink: PropTypes.func,
  onKeyDownNewsTitle: PropTypes.func,
};
export default NewsTile;
