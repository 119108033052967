import '../assets/slick.scss';
import '../assets/slick-theme.scss';
import styles from './NewsCarousel.module.scss';
import './NewsCarousel.scss';
import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { connect, shallowEqual, useDispatch } from 'react-redux';
import { requestNews } from '../behavior/actions';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import {
  useLoadEffect,
  useMatchMediaContext,
} from 'sana/utils';
import { InfoAlert } from 'sana/elements';
import classNames from 'classnames';
import {
  getDefaultNewsList,
  getPageInfo,
  pageMetaSettings,
  sliderMetaSettings,
} from '../utils/constants';
import { featuredImageMapping } from '../utils/handleEvent';
import { TextKeys } from '../utils/textKeysConstants';
import { UseSanaTexts, SimpleText } from 'sana/texts';
import LeftArrow from './customArrows/LeftArrow';
import RightArrow from './customArrows/RightArrow';
import NewsTile from './newsTile/Tile';
import { getDefaultModel } from '../../admin/utils/constants';

const NewsCarousel = ({ model, id, news, expired, requestNews }) => {
  const mediaContext = useMatchMediaContext();
  const carousel = useRef(null);
  const modelRef = useRef();
  const dispatch = useDispatch();
  const elementId = `news-carousel-content-block-${id}`;
  let modelExpired = false;
  if (modelRef.current && !shallowEqual(modelRef.current, model))
    modelExpired = true;

  modelRef.current = model;
  let pageInfo = getPageInfo(mediaContext, model);

  if (modelExpired) {
    pageInfo = getPageInfo(mediaContext, model);
  }

  const [pageMeta, setPageMeta] = useState(pageMetaSettings(pageInfo));
  const [sliderMeta, setSliderMeta] = useState(sliderMetaSettings);
  const [newsList, setNewsList] = useState(getDefaultNewsList(pageInfo));
  const [firstProductIndex, setFirstProductIndex] = useState(0);

  const sliderRef = useRef();
  const refRightArrow = useRef();
  const refLeftArrow = useRef();
  const refsNewsTitles = useRef([]);

  const navigationIconStatus = slidValue => {
    let disabledPrev,
      disabledNext = false;
    if (slidValue <= 0)
      disabledPrev = true;
    if (news?.totalCount <= slidValue + pageInfo.itemPerRow)
      disabledNext = true;
    setSliderMeta({
      ...sliderMeta,
      disabledPrev,
      disabledNext,
    });
  };

  const nextCallback = () => {
    nextAction(); 
    setSliderMeta({
      ...sliderMeta,
      disabledNext: news?.totalCount <= sliderMeta.currentSlide + pageInfo.itemPerRow,
    });
    sliderRef.current.slickNext();
  }; 

  const prevCallback = event => {
    if(!event)
      return;
    sliderRef.current.slickPrev();
    setFirstProductIndex(firstProductIndex-pageInfo.itemPerRow);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 800,
    slidesToShow: pageInfo.itemPerRow,
    slidesToScroll: pageInfo.itemPerRow,
    afterChange: current => navigationIconStatus(current),
  };
  const requestNewsData = useCallback(
    (id, perPageItem, currentPage, init = false) => {
      dispatch(requestNews(id, currentPage, perPageItem));
    },
    [modelExpired, pageMeta],
  );

  const resetStates = useCallback(() => {
    setPageMeta(pageMetaSettings(pageInfo));
    setSliderMeta(sliderMetaSettings);
    setFirstProductIndex(0);
  }, [modelExpired, expired]);

  const disabledNextPrev = () => {
    const disabledPrev = pageMeta.nextPage < 1;
    const disabledNext = news?.totalCount <= sliderMeta.currentSlide + pageInfo.itemPerRow;
    setSliderMeta({
      ...sliderMeta,
      disabledPrev,
      disabledNext,
    });
  };

  const nextAction = debounce(() => {
    if (newsList.length <= pageMeta.totalCount) {
      const isHitAPI = pageMeta.requestHit < pageMeta.totalCount;
      setPageMeta({
        ...pageMeta,
        currentPage: pageMeta.nextPage,
        isLoading: isHitAPI,
        requestHit: isHitAPI
          ? pageMeta.requestHit + pageMeta.perPageItem
          : pageMeta.requestHit,
      });
      if (isHitAPI) {
        requestNewsData(id, pageMeta.perPageItem, pageMeta.nextPage);
      }
    }
    disabledNextPrev();
    setFirstProductIndex(firstProductIndex + settings.slidesToShow);
  }, 400);

  useEffect(() => {
    if (news) {
      setPageMeta({
        ...pageMeta,
        totalCount: news.totalCount,
        isLoading: false,
        numberOfPage: Math.round(
          parseInt(news.totalCount) / pageInfo.itemPerRow,
        ),
        nextPage: pageMeta.nextPage + 1,
      });
      setSliderMeta({
        ...sliderMeta,
        currentSlide: pageMeta.currentPage === 0 ? settings.slidesToShow : parseInt(sliderMeta.currentSlide + settings.slidesToShow),
        disabledPrev: pageMeta.currentPage === settings.slidesToShow || pageMeta.currentPage === 0,
        hideNavigation: pageMeta.perPageItem >= pageMeta.totalCount,
      });
      const exitingNews = newsList.filter(item => item.id !== undefined);
      const unOderData = news.items.concat(exitingNews);
      setNewsList(()=>uniqBy(unOderData, e => e.id));
    }
  }, [news]);

  useLoadEffect(() => {
    if (newsList.length > 0) {
      refsNewsTitles.current[firstProductIndex]?.newsTitle && refsNewsTitles.current[firstProductIndex].newsTitle.focus();
    }
  }, [firstProductIndex]);
  
  useLoadEffect(() => {
    resetStates();
    requestNewsData(id, pageMeta.perPageItem, pageMeta.currentPage, true);
  }, [id,expired, modelExpired]);

  const onKeyDownReadMoreLink = event => {
    if (!event?.shiftKey && event?.key === 'Tab') {
      event.preventDefault();
      refRightArrow.current.focus();
    }
  };

  const onKeyDownNewsTitle = event => {
    if (event?.shiftKey && event?.key === 'Tab') {
      event.preventDefault();
      setTimeout(() => {
        refLeftArrow.current.focus();
      }, 200);
    }
  };

  const onKeyDownRightArrow = event => {
    if (event?.shiftKey && event?.key === 'Tab') {
      event.preventDefault();
      setTimeout(() => {
        const index = (firstProductIndex + pageInfo.itemPerRow) - 1;
        refsNewsTitles.current[index] && refsNewsTitles.current[index].readMoreLink.focus();
        !refsNewsTitles.current[index] && refsNewsTitles.current[refsNewsTitles.current.length - 1].readMoreLink.focus();
      }, 100);
    }
    else if (['Enter', ' '].includes(event?.key)) {
      nextCallback();
    }
  };

  const onKeyDownLeftArrow = event => {
    if(!event?.shiftKey && event?.key === 'Tab'){
     setTimeout(() => {
      refsNewsTitles.current[firstProductIndex] && refsNewsTitles.current[firstProductIndex].newsTitle.focus();
     }, 100);
    }
    else if(['Enter', ' '].includes(event?.key)){
      prevCallback();
    }
  };

  const getRender = getNewData => {
    let element = [];
    for (let index = sliderMeta.index; index < getNewData.length; index++) {
      getNewData[index] = {
        ...getNewData[index],
        image: featuredImageMapping(getNewData[index], model.carousal.defaultImage ?? getDefaultModel().carousal.defaultImage),
      };
      element = element.concat(
        <NewsTile
          ref={element => refsNewsTitles.current[index] = element}
          key={index}
          model={getNewData[index]}
          modelSettings={model}
          onKeyDownReadMoreLink={(index+1)%pageInfo.itemPerRow===0 ? onKeyDownReadMoreLink : undefined}
          onKeyDownNewsTitle={index%pageInfo.itemPerRow===0 ? onKeyDownNewsTitle : undefined}
        />,
      );
    }
    return element;
  };

  const getNewsList = useMemo(() => {
    return getRender(
      !newsList?.length ? (news ? news.items : []) : newsList,
    );
  }, [news, newsList]);

  return (
    <div
      id={elementId}
      className={classNames('news-carousel-content-block')}
      ref={carousel}
    >
      <span
        tabIndex={0}
        className={styles.visuallyHidden}
      >
        {model.accessibilitySettings?.contentElementAltText ? model.accessibilitySettings?.contentElementAltText : <SimpleText textKey={'ContentElementAltText'} />}
      </span>
      { getNewsList.length > 0 ?  (
        <div className={styles.sliderContent}>
           <div className={styles.navigation}>
           {
             <UseSanaTexts textKeys={TextKeys}>{text => (
              <LeftArrow
                ref={refLeftArrow}
                imagePath={model.carousal.previousButtonIcon}
                disabled={sliderMeta.disabledPrev}  
                onClick={prevCallback}
                onKeyDown={onKeyDownLeftArrow}
                ariaLabel={text[3]}
              />
              )}
            </UseSanaTexts>
            }
          </div>
          <Slider ref={slider => (sliderRef.current = slider)} {...settings}>
            {getNewsList}
          </Slider>
          <div className={styles.navigation}>
          {
              <UseSanaTexts textKeys={TextKeys}>{text => (
                <RightArrow
                  ref={refRightArrow}
                  imagePath={model.carousal.nextButtonIcon}
                  disabled={sliderMeta.disabledNext}
                  onClick={nextCallback}
                  apiLoading={pageMeta.isLoading}
                  onKeyDown={onKeyDownRightArrow}
                  ariaLabel={text[2]}
                />
              )}
              </UseSanaTexts>
            }
          </div>
        </div> 
        ) : (
        <UseSanaTexts textKeys={TextKeys}>{text => (
          <InfoAlert>
            {text[0]}
          </InfoAlert>
        )}
      </UseSanaTexts>
      )}
    </div>
  );
};
NewsCarousel.propTypes = {
  model: PropTypes.object,
  id: PropTypes.string.isRequired,
  news: PropTypes.object,
  expired: PropTypes.bool,
  requestNews: PropTypes.func.isRequired,
};

const mapStateToProps = ({ news }, { id }) => {
  const getNews = news && news[id];
  if (!getNews) {
    return { expired: undefined };
  }

  return {
    news: getNews.list,
    expired: getNews.expired,
  };
};

export default connect(mapStateToProps, { requestNews })(NewsCarousel);
